import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/RegisterCheckPage.css'
import lastStep from '../assets/last-step.png';
import Axios from 'axios';

const RegisterCheckPage = () => {
    const apiURL = process.env.REACT_APP_API_URL;
    const { setStep, userData, setUserData, submitData } = useContext(multiStepContext);
    const [options, setOptions] = useState([]);

    const getOperator = async () => {
        try {
            const response = await Axios.get(apiURL + 'getAllOperator');
            setOptions(response.data);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    useEffect(() => {
        getOperator();
    }, [userData]);

    const renderBrandOption = () => {
        const selectedOption = options.find(option => option.id === parseInt(userData.brandOption));
        return selectedOption ? selectedOption.company_name : 'Unknown';
    };

    const renderPhoneNumber = () => {
        if (userData["phoneNumberOption"] === "ไม่ใช่") {
            return userData["phoneNumber"]
        }
        else {
            return "ท่านประสงค์รับซิมการ์ดใหม่"
        }
    }

    const sendDataToAPI = async () => {
        try {
            const response = await Axios.post(apiURL + `saveDisabledData`, {
                nationalid: userData['citizenId'],
                firstname: userData['firstname'],
                lastname: userData['lastname'],
                operator: parseInt(userData.brandOption),
                status: "submit",
                gFirstname: userData["legalFirstname"],
                gLastname: userData["legalLastname"],
                gNationalId: userData["legalId"],
                typeId: userData.selectedOption,
                phoneNumber: userData["phoneNumber"],
                purpose: userData["phoneNumberOption"],
                dType: userData.DType
            });
        } catch (error) {
            console.error('Error sending data to API:', error);
        }
    };

    return (
        <>
            {
                <div className="reg-acc-body">
                    <div className="reg-acc-container">
                        <div className="reg-acc-area-con">
                            <div className="reg-acc-process-bar">
                                <img src={lastStep} className="last-step-bar" alt="OTP Process Bar" />
                            </div>
                            <div className="reg-acc-info">
                                โปรดตรวจสอบความถูกต้อง
                                <br />
                                <span style={{ fontSize: '16' }}>Please check the accuracy</span>
                            </div>
                            <div className="reg-acc-service-detail">
                                <div className="acc-service">
                                    ชื่อ
                                    <input type="text" className='reg-acc-text' value={userData.firstname} readOnly></input>
                                </div>
                                <div className="reg-acc-phone">
                                    นามสกุล
                                    <input type="text" className='reg-acc-text' value={userData.lastname} readOnly></input>
                                </div>
                            </div>
                            <div className='reg-acc-package'>
                                หมายเลขบัตรประชาชน / บัตรสวัสดิการ / บัตรประจําตัวผู้พิการ
                                <input type="text" className='reg-status-id' value={userData['citizenId']} readOnly></input>
                            </div>
                            <div className="reg-acc-service-detail">
                                <div className="acc-service">
                                    เครือข่าย
                                    <input type="text" className='reg-acc-text' value={renderBrandOption()} readOnly></input>
                                </div>
                                <div className="reg-acc-phone">
                                    หมายเลขโทรศัพท์
                                    <input type="text" className='reg-acc-text' value={renderPhoneNumber()} readOnly></input>
                                </div>
                            </div>
                            <div className="reg-acc-bt">
                                <button onClick={() => setStep(7)} className="reg-acc-back-bt">ย้อนกลับ</button>
                                <button onClick={() => { sendDataToAPI(); submitData(); setStep(8); }} className="reg-acc-next-bt">ยืนยันข้อมูล</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default RegisterCheckPage;