import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/CompleteRegisterPage.css';
import '../Styles/AdvicePage.css';
import completeIcon from '../assets/successReg-img.png';

const AdvicePage = () => {
    const { setStep, userData, setUserData } = useContext(multiStepContext);

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="complete-container">
            <div className='complete-regis'>
                คำแนะนำ
                <br />
                <span style={{ fontSize: '16' }}>Advice</span>
            </div>
            <div className="complete-img-container">
                <img src={completeIcon} className={`complete-reg-img ${windowHeight < 650 ? 'hidden-notFound' : 'complete-reg-img'}`}></img>
            </div>
            <div className='advice-container'>
                กรณีที่ท่านใช้งานเครือข่ายอื่นและมีความประสงค์เลือกใช้เบอร์เดิม ต้องปฏิบัติตามคำแนะนำดังนี้
                <ol className='advice-text'>
                    <li>
                        ผู้รับสิทธิ์ ต้องทำการย้ายค่ายมายังค่ายที่เข้าร่วมโครงการ โดยการกด *151*เลขบัตรประชาชน#โทรออก
                    </li>
                    <li>
                        นำรหัสย้ายค่ายที่ได้รับ แจ้งไปยังค่ายใหม่ ที่ย้ายไป เพื่อขอรับซิมการ์ดใหม่
                    </li>
                    <li>
                        เมื่อย้ายค่ายเรียบร้อยแล้ว จึงจะกลับมาลงทะเบียนรับสิทธิ์อีกครั้ง โดยเลือกเมนู ใช้หมายเลขโทรศัพท์เดิม
                    </li>
                </ol>
            </div>
            <div className="advice-button-section">
                <button onClick={() => setStep(6)} className="form-back-bt">
                    ย้อนกลับ
                </button>
                <button onClick={() => {
                    setStep(1)
                    setUserData(null)
                }}
                    className="complete-register-bt">
                    กลับสู่หน้าหลัก
                </button>
            </div>
        </div>
    )
}

export default AdvicePage