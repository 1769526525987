import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/AlreadySelectPage.css';
import Axios from 'axios';

const AlreadySelectPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const { setStep, userData } = useContext(multiStepContext);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const statusId = userData.statusId;
  const [type, setType] = useState(null); // Initialize as null
  const [purpose, setPurpose] = useState(null); // Initialize as null

  const getConfirmData = async (statusId) => {
    const response = await Axios.get(apiURL + `checkOperationStatus/${statusId}`);
    setType(response.data);
  };

  const getPurpose = async (statusId) => {
    const response = await Axios.get(apiURL + `getOperationRecord/${statusId}`);
    setPurpose(response.data);
  };

  useEffect(() => {
    if (statusId) {
      getConfirmData(statusId);  
      getPurpose(statusId);  
    }
  }, [statusId]);

  const fname = type?.disabled?.firstName || '';
  const lname = type?.disabled?.lastName || '';
  const serviceDetail = type?.OpInfo?.[0]?.operators?.company_name || '';
  const packageType = type?.OpInfo?.[0]?.packages?.packageName || '';
  const numberDetail = type?.OpInfo?.[0]?.phoneNumber || '';
  const purposeNumber = purpose?.purpose || '';
  const location = type?.OpInfo?.[0]?.branchs?.name || '';

  const handleLocation = () => {
    if (purposeNumber === "ใช่") {
      if (type?.OpInfo?.[0]?.branchs) {
        return (
          <div className='acc-phone'>
            รับซิมการ์ดที่ศูนย์บริการ
            <input type="text" className='already-text' value={location} readOnly />
          </div>
        );
      } else if (type?.OpInfo?.[0]?.address) {
        const fullAddress = `${type.OpInfo[0].address.addressName || ''} ${type.OpInfo[0].address.subDistrict || ''} ${type.OpInfo[0].address.district || ''} ${type.OpInfo[0].address.province || ''} ${type.OpInfo[0].address.zipCode || ''}`.trim();
        return (
          <div className='acc-phone'>
            ที่อยู่การจัดส่ง
            <input type="text" className='already-text' value={fullAddress} readOnly />
          </div>
        );
      }
    } else {
      return null;
    }
  };

  const renderPurpose = () => {
    return numberDetail ? numberDetail : 'รับซิมการ์ดใหม่';
  };

  return (
    <div className="already-container">
      <div className='already-info'>
        ท่านได้ยืนยันสิทธิ์เป็นที่เรียบร้อย
        <br />
        <span style={{ fontSize: '16' }}>You've already confirmed</span>
      </div>
      <div className="already-info-con">
        <div className="already-fullname">
          <div className="already-fname">
            ชื่อ
            <input type="text" className='already-text' value={fname} readOnly />
          </div>
          <div className="already-lname">
            นามสกุล
            <input type="text" className='already-text' value={lname} readOnly />
          </div>
        </div>
        <div className="already-service-detail">
          <div className="already-service">
            เครือข่าย
            <input type="text" className='already-text' value={serviceDetail} readOnly />
          </div>
          <div className="already-phone">
            หมายเลขโทรศัพท์
            <input type="text" className='already-text' value={renderPurpose()} readOnly />
          </div>
        </div>
        <div className='already-package'>
          <div className="already-service">
            แพ็คแกจ
            <input type="text" className='already-text' value={packageType} readOnly />
          </div>
          {handleLocation()}
        </div>
      </div>
      <div className="already-register-con">
        <button onClick={() => setStep(1)} className="already-register-bt">กลับสู่หน้าหลัก</button>
      </div>
    </div>
  );
}

export default AlreadySelectPage;