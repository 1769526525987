import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/SimMethod.css';
import '../Styles/SimAddressDefault.css';
import lineQR from '../assets/qrcode_line.png';
import Axios from 'axios';

const SimAddressDefault = () => {
    const apiURL = process.env.REACT_APP_API_URL;
    const { setStep, userData, setUserData } = useContext(multiStepContext);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [addressOption, setAddressOption] = useState("");
    const [district, setDistrict] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [province, setProvince] = useState("");
    const [subDistrict, setSubDistrict] = useState("");
    const [addressName, setAddressName] = useState(userData.addressName || "");
    const [email, setEmail] = useState(userData.email || "");
    const [lineId, setLineId] = useState(userData.lineId || "");
    const [phoneNumber, setPhoneNumber] = useState(userData.phoneNumber || "");

    useEffect(() => {
        const getAddressData = async () => {
            try {
                const response = await Axios.get(apiURL + `getPersonAddress/${userData.statusId}`);
                setDistrict(response.data.MAIMAD_CURR_DISTRICT_NAME);
                setZipCode(response.data.MAIMAD_CURR_POST_CODE);
                setProvince(response.data.MAIMAD_CURR_PROVINCE_NAME);
                setSubDistrict(response.data.MAIMAD_CURR_SUBDISTRICT_NAME);
            } catch (error) {
                console.error('API call failed:', error);
            }
        };

        getAddressData();
    }, []);

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };

    const closeInfoPopup = () => {
        setIsPopupVisible(false);
    };

    const handleOptionChange = (e) => {
        setAddressOption(e.target.value);
    };

    const handleNextStep = () => {
        if (addressOption === "default") {
            if (addressName.trim() === "" || phoneNumber.trim().length !== 10) {
                alert("กรุณากรอกข้อมูลที่อยู่และเบอร์โทรติดต่อให้ครบถ้วน");
                return;
            }
            setUserData({
                ...userData,
                addressName,
                district,
                zipCode,
                province,
                subDistrict,
                email,
                lineId,
                phoneNumber,
                addressOption
            });
            setStep(13);
        } else if (addressOption === "new") {
            setUserData({
                ...userData,
                addressOption
            })
            setStep(31);
        } else {
            alert("กรุณาเลือกที่อยู่การจัดส่ง (Please select an address for receiving the SIM card).");
        }
    };

    return (
        <>
            <div className="sim-body">
                <div className="sim-container">
                    <div className="sim-area-con">
                        <div className="sim-info">
                            ที่อยู่การจัดส่งซิมการ์ด
                            <br />
                            <span style={{ fontSize: '16' }}>SIM card delivery address.</span>
                        </div>
                        <div className="sim-fill-area">
                            <div className="radio-container">
                                <label className="radio-label">
                                    <input
                                        type="checkbox"
                                        name="simAddress"
                                        value="default"
                                        checked={addressOption === "default"}
                                        onChange={handleOptionChange}
                                    />
                                    จัดส่งที่อยู่ตามบัตรประชาชน
                                </label>
                                <div className='acc-package'>
                                    ที่อยู่/บ้านเลขที่/อาคาร/ถนน/ซอย
                                    <input
                                        type="text"
                                        className='address-name'
                                        disabled={addressOption !== "default"}
                                        value={addressName}
                                        onChange={(e) => setAddressName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="acc-service-detail">
                                    <div className="acc-service">
                                        ตำบล
                                        <input type="text" className='address-sub-district' value={subDistrict} disabled />
                                    </div>
                                    <div className="acc-phone">
                                        อำเภอ
                                        <input type="text" className='address-district' value={district} disabled />
                                    </div>
                                </div>
                                <div className="acc-service-detail">
                                    <div className="acc-phone">
                                        จังหวัด
                                        <input type="text" className='address-province' value={province} disabled />
                                    </div>
                                    <div className="acc-service">
                                        รหัสไปรษณีย์
                                        <input type="text" className='address-zipcode' value={zipCode} disabled />
                                    </div>
                                </div>
                                <div className="acc-service-detail">
                                    <div className="acc-phone">
                                        เบอร์โทรติดต่อ
                                        <input
                                            type="text"
                                            className="address-phone-number"
                                            disabled={addressOption !== "default"}
                                            value={phoneNumber}
                                            onChange={(e) => {
                                                const onlyDigits = e.target.value.replace(/\D/g, '');
                                                if (onlyDigits.length <= 10) {
                                                    setPhoneNumber(onlyDigits);
                                                }
                                            }}
                                            maxLength="10"
                                            required
                                        />
                                    </div>
                                    <div className="acc-phone">
                                        อีเมล (ถ้ามี)
                                        <input
                                            type="text"
                                            className='address-email'
                                            disabled={addressOption !== "default"}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="acc-service-detail">
                                    <div className="acc-half">
                                        Line ID (ถ้ามี)
                                        <input
                                            type="text"
                                            className='address-line-id'
                                            disabled={addressOption !== "default"}
                                            value={lineId}
                                            onChange={(e) => setLineId(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="radio-container" style={{ marginTop: '15px' }}>
                                <label className="radio-label">
                                    <input
                                        type="checkbox"
                                        name="simAddress"
                                        value="new"
                                        checked={addressOption === "new"}
                                        onChange={handleOptionChange}
                                    />
                                    จัดส่งที่อยู่ใหม่
                                </label>
                            </div>
                            <div className='sim-submit-area'>
                                <label>
                                    <a href="#" className="to-service" onClick={togglePopup}>ติดต่อเจ้าหน้าที่</a>
                                </label>
                                <div className='sim-button'>
                                    <button onClick={() => setStep(29)} className="form-back-bt">
                                        ย้อนกลับ
                                    </button>
                                    <button onClick={handleNextStep} className="authen-next-button">
                                        ต่อไป
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isPopupVisible && (
                <div className="popup-overlay">
                    <div className="popup-window">
                        <div className="popup-header">
                            <span className="popup-title">ติดต่อเจ้าหน้าที่</span>
                            <button className="popup-close-btn" onClick={closeInfoPopup}>
                                &times;
                            </button>
                        </div>
                        <div className="popup-content" style={{ paddingTop: '0px' }}>
                            <ul className='popup-content-text'>
                                <li>
                                    ช่องทางการติดต่อผู้ให้บริการ Infinite sim บนโครงข่ายโทรคมนาคมแห่งชาติ โครงการอินเตอร์เน็ตฟรีสำหรับผู้พิการและนักเรียนยากจน
                                    <span style={{ marginLeft: '5px' }}>
                                        <a href="https://lin.ee/EgE32QQ" target="_blank" rel="noopener noreferrer" className="popup-link">
                                            https://lin.ee/EgE32QQ
                                        </a>
                                    </span>
                                </li>
                                <li>LineID@infinite_netnbtc</li>
                            </ul>
                            <img src={lineQR} className='popup-line-qr' alt="QR Code" />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SimAddressDefault;