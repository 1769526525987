import React, { useContext, useState, useEffect } from 'react';
import { multiStepContext } from '../StepContext';
import '../Styles/UserInfoPage.css'
import formProcessBar from '../assets/form-status-bar.png';
import Axios from 'axios';

const UserInfoPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const {setStep, userData, setUserData} = useContext(multiStepContext);
  const nationalId = userData.citizenId;

  const checkClient = async (nationalId, userData) => {
    try {
      const response = await Axios.post(apiURL+`checkInfo/${nationalId}`, {
        MAIMAD_FIRST_NAME_THAI: userData.firstname,
        MAIMAD_LAST_NAME_THAI: userData.lastname
      });
      return response.data.result
    } catch (error) {
      console.error('Error sending data to API:', error);
    }
  }
  
  const checkType = async (nationalId) => {
    const response = await Axios.post(apiURL+`checkPersonDT/${nationalId}`,{
      typeSelected : userData.selectedOption
    });
    return response.data
  }

  const handlePageStep = async () => {

    let typeResult = await checkType(nationalId);
    let clientResult = await checkClient(nationalId, userData);

    if (typeResult.result === "Match"  && clientResult === "Match") {
        setUserData({ ...userData, DType: typeResult.DType });
        setStep(6);
    } else {
        setStep(15);
    }
  }

  const checkGuadian = () => {
    if (userData.selectedOption !== 4){
      handlePageStep();
    }
    else{
      setStep(5);
    }
  }

  const handleFirstNameChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setUserData({ ...userData, firstname: trimmedValue });
  }
  
  const handleLastNameChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setUserData({ ...userData, lastname: trimmedValue });
  }
 
  return (
    <div className="userInfo-container">
        <div className="userInfo-process-bar">
            <img src={formProcessBar} className="userInfo-pro-bar" alt="Form Process Bar" />
        </div>
        <div className="userInfo-info">
            โปรดกรอกข้อมูลในแบบฟอร์ม
            <br />
            <span style={{ fontSize: '16' }}>Please indicate the most suitable option</span>
        </div>
        <div className="userInfo-input">
            <div className="userInfo-name">
              <div className="userInfo-fname">
                   ชื่อ
                   <input
                      className="form-text"
                      type='text'
                      placeholder='โปรดกรอกข้อมูล'
                      value={userData['firstname']}
                      onChange={handleFirstNameChange}
                    />
              </div>
              <div className="userInfo-lname">
                  นามสกุล
                  <input
                      className="form-text"
                      type='text'
                      placeholder='โปรดกรอกข้อมูล'
                      value={userData['lastname']}
                      onChange={handleLastNameChange}
                    />
              </div>
            </div>
            <div className="userInfo-id">
              <div className="userInfo-id">
                หมายเลขบัตรประชาชน
                <input 
                    type="text" 
                    className='form-id' 
                    placeholder='โปรดกรอกหมายเลขบัตรประชาชน' 
                    value={userData['citizenId']} 
                    disabled
                    readOnly
                    // onChange={(e) => setUserData({ ...userData, "citizenId": e.target.value })}
                  />
              </div>
            </div>
        </div>
        <div className="form-bt">
            <button onClick={() => setStep(3)} className="form-back-bt">ย้อนกลับ</button>
            <button onClick={checkGuadian} className="form-next-bt">ต่อไป</button>
        </div>
    </div>
  );
};

export default UserInfoPage;